import React, { useEffect, useState } from 'react';
import { external1, arrowimg, boost_bg, account_step, account_step2, account_step3, account_step4, arrow, banner1, banner2, banner3, banner4, banner5, banner6, card1, card2, card3, card4, card_vector, carduser1, carduser2, carduser3, circle1, circle2, halfstar, insight1, insight2, insight3, join_img1, join_img2, onestart, start, worthwhile2, worthwhile_center_image, worthwhile_img, logo, bridge_icon1, bridge_icon2, bridge_icon3, bridge_icon4, footer_logo, Accordion_icon, accordion_icon1, accordion_icon2, accordion_icon6, accordion_icon4, accordion_icon3, account_step5, about_left_img, rcm_logo, rcm_logo2, } from '../../assets/images';
import { Accordion, Button, Card, Carousel, Modal } from 'react-bootstrap';
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/js/uikit-icons.min.js'; // Optional if you want to use icons
import { useForm } from 'react-hook-form';



const testimonials = [
    {
        title: 'Exceptional Service and Accuracy',
        quote: 'ProBill RCM has truly transformed our billing process. Their payment posting services are quick and accurate, and we’ve seen a significant improvement in our cash flow. The team is professional, responsive, and detail-oriented. Highly recommend!"– Dr. Sarah Thompson, Healthcare Clinic',
        name: "Wade Warren",
        role: "Lorem ipsum dolor",
        rating: 2.2,
        img: carduser1, // You can replace this with actual images
    },
    {
        title: 'Efficient and Reliable',
        quote: "Working with ProBill RCM has been a game-changer for our practice. Their team handles everything from denial management to insurance payments seamlessly. Our revenue cycle has never been smoother, and their efficiency has saved us both time and money– John Myers, Practice Manager.",
        name: "Wade Warren",
        role: "Lorem ipsum dolor",
        rating: 1.2,
        img: carduser2,
    },
    {
        title: 'Outstanding Denials Management',
        quote: 'We struggled with managing claim denials before partnering with ProBill RCM. Their expertise in appeals and denial management has helped us recover significant revenue we would have otherwise lost. Excellent service all around!"– Lisa Perez, Medical Billing Specialist ',
        name: "Wade Warren",
        role: "Lorem ipsum dolor",
        rating: 2.2,
        img: carduser3,
    },
    {
        title: 'Exceptional Service and Accuracy',
        quote: 'ProBill RCM has truly transformed our billing process. Their payment posting services are quick and accurate, and we’ve seen a significant improvement in our cash flow. The team is professional, responsive, and detail-oriented. Highly recommend!"– Dr. Sarah Thompson, Healthcare Clinic',
        name: "Wade Warren",
        role: "Lorem ipsum dolor",
        rating: 3.2,
        img: carduser2,
    },
    {
        title: 'Efficient and Reliable',
        quote: "Working with ProBill RCM has been a game-changer for our practice. Their team handles everything from denial management to insurance payments seamlessly. Our revenue cycle has never been smoother, and their efficiency has saved us both time and money– John Myers, Practice Manager.",
        name: "Wade Warren",
        role: "Lorem ipsum dolor",
        rating: 4.8,
        img: carduser2,
    },
    {
        title: 'Expert Payment Posting Team',
        quote: 'The payment posting service provided by ProBill RCM is top-notch. Their attention to detail ensures accurate processing every time, which has made a huge difference in our practice’s financial health. Their team is always available to answer questions and provide updates."– Dr. Michael Lee, Private Practice ',
        name: "Wade Warren",
        role: "Lorem ipsum dolor",
        rating: 4.1,
        img: carduser2,
    }
];
const Section = () => {

    // const [activeTab, setActiveTab] = useState(1);
    // const handleTabClick = (tabIndex) => {
    //     setActiveTab(tabIndex);
    // };

    const groupedTestimonials = [];
    for (let i = 0; i < testimonials.length; i += 3) {
        groupedTestimonials.push(testimonials.slice(i, i + 3));
    }

    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleCards, setVisibleCards] = useState(3.5);

    const updateVisibleCards = () => {
        if (window.innerWidth < 1024) {
            setVisibleCards(1);
        } else {
            setVisibleCards(3.5);
        }
    };

    useEffect(() => {
        updateVisibleCards();
        window.addEventListener('resize', updateVisibleCards);

        return () => {
            window.removeEventListener('resize', updateVisibleCards);
        };
    }, []);

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex < testimonials.length - visibleCards ? prevIndex + 1 : prevIndex
        );
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();

    const onSubmit = (data) => {
        console.log("Form Data: ", data);
        reset();
    };

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    // accordion
    const [activeAccordion, setActiveAccordion] = useState(null);
    const handleAccordionToggle = (eventKey) => {
        setActiveAccordion(prev => (prev === eventKey ? null : eventKey));
    }
    const getImageToShow = () => {
        switch (activeAccordion) {
            case "0":
                return account_step2;
            case "1":
                return account_step3;
            case "2":
                return account_step4;
            case "3":
                return account_step5;
            default:
                return account_step;
        }
    };
    return (
        <section className="hero-section  text-center ">
            <div className="container bg-bg   ">
                {/* Top Floating Images */}
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="hero-text     " >
                            Simplify Your  <span className="text-orange">Medical Billing.</span> <br />
                            Maximize Your Revenue

                        </h1>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-header-second">
                <div className="container ">
                    {/* Hero Text Section */}
                    <div className="row mx-auto " >
                        <div className="  col-lg-12 col-12 ">
                            <div className="mx-5 d-flex justify-content-center">
                                <p className="lead  w-75  " >
                                    Welcome to Probill RCM, your reliable partner in medical billing and collections. We streamline the billing process,
                                    allowing you to prioritize patient care while our skilled professionals and cutting-edge technology work to optimize your revenue. </p>
                            </div>
                            <button className="bt border-0 btn-warning position-relative  mt-3 mb-md-5 " type="submit" onClick={handleShow} > Contact Us</button>
                        </div>
                    </div>
                    {/* Floating Cards */}
                </div>
            </div>
            {/* <div className=""> */}
            {/* <div className=""> */}
            <div className="row mx-auto card-bg-img mt-lg-0 mt-1 p-0" >
                <div className=" col-6 mt-lg-0 mt-2 d-flex flex-lg-row justify-content-lg-center justify-content-evenly align-items-center">
                    <div
                        className="card card-width card-rotate p-3"
                    >
                        <img
                            src={card2}
                            className="img-fluid card-img mx-auto m-0"
                            alt=""
                            style={{ transform: 'rotate(6deg)' }} // Correct property for rotation
                        />
                        <p className="floting-card-title m-0">Solo Practitioners</p>
                        <p className="floting-card-desc mb-1 m-lg-0 text-muted px-1">
                            Our billing and coding services assist  accurate claims submissions
                            and prompt payments , helping you maximize revenue while reducing
                            administrative workload.
                        </p>
                    </div>
                    {/* <div
                                className="card card-width card-rotate-1 p-2"

                            >
                                <img
                                    src={card1}
                                    className="img-fluid card-img mx-auto m-0"
                                    alt=""
                                />
                                <p className="floting-card-title m-0">
                                    Compliance-Focused Billing Solutions
                                </p>
                                <p className="floting-card-desc mb-1 text-muted">
                                    We help healthcare providers seamlessly optimize their medical
                                    billing process, ensuring sustainability and adherence to regulatory
                                    standards.
                                </p>
                            </div> */}
                </div>
                <div className="col-lg-6 mt-lg-0 mt-2 col-6 d-flex  flex-lg-row justify-content-lg-center justify-content-evenly align-items-center">
                    <div
                        className="card card-width card-rotate-2 p-3 text-center"
                    >
                        <img
                            src={card4}
                            className="img-fluid card-img mx-auto m-0"
                            alt=""
                        />
                        <p className="floting-card-title m-0">Streamlined Billing for Clinics</p>
                        <p className="floting-card-desc mb-1 m-lg-0 text-muted px-1">
                            We offer expert billing and  helping clinics navigate
                            complex regulations for accurate claims and timely payments.
                        </p>
                    </div>
                    {/* <div
                                className="card card-width card-rotate-3 p-3"

                            >
                                <img
                                    src={card3}
                                    className="img-fluid card-img mx-auto m-0"
                                    alt=""
                                />
                                <p className="floting-card-title mt-1 m-0">
                                    Tech-Powered RCM Accelerating Your Success
                                </p>
                                <p className="floting-card-desc mb-1 text-muted px-1">
                                    Probill RCM delivers high-quality services to healthcare providers,
                                    focusing on accelerating practice.
                                </p>
                            </div> */}
                </div>
            </div>
            {/* </div> */}
            {/* </div> */}
            {/* BOOST */}
            <div className='boost_main'>
                <div className="container pt-lg-1">
                    <div className="row mx-auto ">
                        <div className="col-12 col-lg-6 d-flex flex-column justify-content-between align-items-center p-4">
                            <div className="d-lg-flex shadow p-0 mx-1 mb-4 mb-lg-3 add_hover_effect">
                                <img src={join_img1} className='  add_width_height_img p-0' alt="" />
                                <div className="div text-start mt-lg-4 p-3 px-4">
                                    <div className="card-title"> <img src={rcm_logo} className="img-fluid mt-lg-0 mt-1 mx-lg-0 join-logo  " alt="logo" />  <img src={rcm_logo2} className="img-fluid mt-1  join-logo2 mx-lg-1" alt="logo" /> <span className=''>Approach</span> </div>
                                    <div className="card-text pt-1 ">Precision Revenue Optimization Method, is a comprehensive framework designed to streamline billing operations, minimize errors, and maximize efficiency. By leveraging industry best practices, and advanced analytics, we focus on optimizing every step of the revenue cycle.</div>
                                    {/* <div className="join-bottom text-end ">
                                        <p className="wishlist mt-lg-5 mb-0">Join Wishlist</p>
                                    </div> */}
                                </div>
                            </div>
                            <div className=" d-lg-flex shadow mx-1 mt-lg-0 m-0 mt-2 mt-md-0 p-0    add_hover_effect" >
                                <img src={join_img2} className='  add_width_height_img p-0' alt="" />
                                <div className="div text-start mt-lg-4 p-3 px-4">
                                    <div className="card-title ">Customer first Philisophy</div>
                                    <div className="card-text pt-1 ">Our mission is to exceed client expectations by providing outstanding service, unmatched adaptability, and exceptional value in revenue cycle management. We are committed to delivering solutions that drive efficiency and results.</div>
                                    {/* <div className="join-bottom text-end ">
                                        <p className="wishlist mt-lg-5 mb-0">Join Wishlist</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 ps-lg-0 text-lg-end skills p-2 p-lg-0 mt-lg-5 mt-0">
                            <div className='row'>
                                <div className='col-12'>
                                    <h1 className=' m-0 mt-lg-3'>......10x</h1>
                                </div>
                                <div className='col-12 ps-lg-0 d-lg-flex align-items-center '>
                                    <div className='row mx-auto'>
                                        <div className='col px-4 pe-0'>
                                            <img src={boost_bg} alt='' className='bg-line d-none d-lg-block' />
                                        </div>
                                        <div className='col-lg-4 col-12  '>
                                            <h1 className='boost   m-0'>Boost</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12  text-end'>
                                    <h2 className='fw-medium mb-4 mt-lg-1 add_font_revenue'>Your Success</h2>
                                    <p className='any-para  mt-2 mt-lg-3 ms-lg-5  text-end '>Selecting the right revenue cycle management (RCM) provider is a critical step in boosting your organization's efficiency and success. A trusted, top-tier partner offers more than just support—they become an extension of your team, delivering solutions that drive revenue growth, streamline processes, and enhance overall performance.</p>

                                </div>
                            </div>

                            {/* <button className="bt border-0 btn-warning mt-lg-2" onClick={handleShow}>Get Started</button> */}
                            <div className="d-flex justify-content-lg-end p-2 mt-lg-4 mt-0 newEmpowering" >
                            <div className="exprence mt-lg-3 p-3 p-lg-0 d-flex flex-column justify-content-lg-end justify-content-start  border-end px-lg-5 ps-3 text-lg-end">
                                    <div className="d-flex justify-content-lg-end">
                                        <div className="circle rounded-circle  d-flex justify-content-center align-items-center p-2">
                                            <img src={circle2} className='img-fluid' style={{width:'32px', height:'32px'}} alt="" />
                                        </div>
                                    </div>
                                    <p className='revenue-circle text-start text-lg-end'>Empowering Your Practice Transforming Revenue Cycle Management </p>
                                </div>
                                <div className="exprence px-lg-3 mt-lg-3 p-3 p-lg-0 ps-4 ps-lg-0   pe-lg-0 px-3 px-lg-0  text-lg-end">
                                    <div className="d-flex justify-content-lg-end">
                                        <div className="circle rounded-circle p-2  d-flex justify-content-center align-items-center">
                                            <img src={circle1} className='img-fluid me-2' style={{width:'32px', height:'32px'}} alt="" />
                                        </div>
                                    </div>
                                    <p className='revenue-circle text-start text-lg-end'>Illuminating Efficiency in Medical Billing: Driving Financial Excellence</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* new section add */}
            <div className="bg-light mt-lg-5 pt-3 mb-0 mb-lg-0 pb-3">
                <div className="container mb-4 mb-lg-0 mt-lg-2 ">
                    <div className="row">

                        <div className=" col-12 p-0   ">
                            <h1 className='text-probill-bridge d-none d-lg-block m-lg-0 ' style={{ top: '75px', fontSize: '48px', fontFamily: 'Oswald', fontWeight: '600' }}>Bridging the Gap
                            </h1>
                            <h1 className='text-probill-rcm  d-lg-none mt-3' style={{ top: '35px' }}>Bridging the Gap
                            </h1>
                            <div className="div d-flex justify-content-center span-line m-0"><span className="span-line mt-3 d-none d-lg-block mb-3" style={{ width: '190px', height: '5px', backgroundColor: '#f7941d' }}></span></div>
                            <div className="div d-flex justify-content-center">
                                <p className='w-50 text-muted m-0 bridge-img-desc'>Our commitment to excellence is a promise to bridge the gaps and deliver unparalleled results for your practice. Achieve financial clarity and navigate through complexities with ease through our service called </p>

                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='setu fw-normal w-50  '><b>SETU: The Bridge</b> </p>

                            </div>


                            <div className="d-flex justify-content-center">
                                <div className="div  bg-img-bridge"></div>

                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mt-lg-4 mb-lg-3 bridge-row  ">
                        <div className="col-12 col-lg-3 mt-3 mt-lg-0 mb-3  d-flex  justify-content-lg-center  justify-content-center ">
                            <div className="card-pillar1 border-0 bridge-card  bg-light  text-center">
                                <div className="d-flex mb-lg-2 justify-content-center">
                                    <div className=' d-flex justify-content-center p-0 align-items-center' style={{ width: '65px', height: '60px', backgroundColor: '#272D40' }}>
                                        {/* <img src={bridge_icon1} className='img-fluid mx-auto' alt="" style={{ width: '40px', height: '40px' }} /> */}
                                        <h1 className='text-white setu-first-l  '
                                            style={{
                                                margin: 0,
                                                lineHeight: '60px', /* Matches the height of the container */
                                                fontSize: '40px', /* Adjust font size if necessary */
                                            }}
                                        >S</h1>
                                    </div>

                                </div>
                                <p className="bridge-card-title mb-lg-1  d-flex justify-content-center text-start">
                                    Strategic Insights
                                </p>
                                <p className="bridge-card-desc text-start  ">
                                    We are grounded in sound research and proactively analyze trends and refine processes to assist optimal outcomes.

                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mt-3 mt-lg-0 d-flex  justify-content-lg-center justify-content-center  ">
                            <div className="card-pillar2 border-0  bridge-card mt-lg-0 bg-light">
                                <div className="d-flex mb-lg-2 justify-content-center">
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            width: '65px',
                                            height: '60px',
                                            backgroundColor: '#272D40',
                                        }}
                                    >
                                        <h1
                                            className="text-white setu-first-l"
                                            style={{
                                                margin: 0,
                                                lineHeight: '60px', /* Matches the height of the container */
                                                fontSize: '40px', /* Adjust font size if necessary */
                                            }}
                                        >
                                            E
                                        </h1>
                                    </div>


                                </div>
                                <p className="bridge-card-title mb-lg-1 ">
                                    Expert Education
                                </p>
                                <p className="bridge-card-desc text-start ">
                                    We stay ahead of the curve with in-depth knowledge of the ever-evolving medical industry, ensuring you are always equipped with the latest insights.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mt-3 mt-lg-0 d-flex justify-content-lg-center justify-content-center ">
                            <div className="card-pillar3 border-0  bridge-card mt-2  mt-lg-0 bg-light">
                                <div className="d-flex mb-lg-2 justify-content-center">
                                    <div className=' d-flex justify-content-center align-items-center' style={{ width: '65px', height: '60px', backgroundColor: '#272D40' }}>
                                        {/* <img src={bridge_icon3} className='img-fluid' alt="" style={{ width: '40px', height: '40px' }} /> */}
                                        <h1 className='text-white setu-first-l d-flex justify-content-center align-items-center '
                                            style={{
                                                margin: 0,
                                                lineHeight: '60px', /* Matches the height of the container */
                                                fontSize: '40px', /* Adjust font size if necessary */
                                            }}
                                        >T</h1>

                                    </div>

                                </div>
                                <p className="bridge-card-title mb-lg-1  ">
                                    Tailored Solutions
                                </p>
                                <p className="bridge-card-desc text-start  ">
                                    Much like a finely tailored suit, every aspect of our approach is meticulously crafted to meet your needs.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-3 d-flex justify-content-lg-center justify-content-center  ">
                            <div className="card-pillar4 mb-lg-0 mb-5 border-0  bridge-card mt-lg-0 mt-2  bg-light">
                                <div className="d-flex justify-content-center">
                                    <div className=' d-flex justify-content-center align-items-center ' style={{ width: '65px', height: '60px', backgroundColor: '#272D40' }}>
                                        {/* <img src={bridge_icon4} className='img-fluid' alt="" style={{ width: '40px', height: '40px' }} /> */}
                                        <h1 className='text-white  setu-first-l d-flex justify-content-center align-items-center '
                                            style={{
                                                margin: 0,
                                                lineHeight: '60px', /* Matches the height of the container */
                                                fontSize: '40px', /* Adjust font size if necessary */
                                            }}
                                        >U</h1>

                                    </div>

                                </div>
                                <p
                                    className="bridge-card-title  d-none d-lg-block"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'visible', // Ensure no hidden overflow
                                        textOverflow: 'clip', // Ensure no text gets truncated
                                        display: 'inline-block', // Ensure it stays on one line
                                    }}
                                >
                                    Unmatched Denial Management
                                </p>

                                <p className="bridge-card-title mt  d-lg-none  ">
                                    Unmatched Denial Management
                                </p>
                                <p className="bridge-card-desc d-flex m-lg-0 justify-content-center align-items-center text-start">
                                    Harnessing the power of AI and ML, we accurately predict and prevent denials, ensuring faster reimbursements.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            {/* Ensure Your Spending Is Worthwhile */}
            {/* <div className="container mt-lg-5 mt-5">
                <div className="row mx-auto">
                    <div className="col-lg-10 col-12 mx-auto mt-lg-5 mb-lg-4 text-center">
                        <h1 className=' text-probill-rcm'>Unlock Revenue Cycle <span className='text-probill-rcm-orange'>Management</span>  for Healthcare  Providers on One <span className='text-probill-rcm-orange'>Platform</span> </h1>
                        <p className='text-probill-para mt-lg-4'>Our solution streamlines everything from electronic appointment management to accelerating reimbursements and delivering actionable business intelligence. </p>
                    </div>
                </div>
            </div>
            <div className="container mb-lg-5">
                <div className="row mx-auto cards">
                    <div className="col-lg-4  col-12  mt-lg-1 ">
                        <article className="card1 card--1">
                            <div className="card__content1">
                                <div className="card__info text-start">
                                    <p className="py-2 m-0 card__category ">Efficiency & Expertise</p>
                                    <p className='card-center-title '>We assist clients in optimizing their medical revenue cycle management,</p>
                                    <p className=" card__title ">Enhancing process efficiency, and providing expert guidance on billing documentation, CPT, and ICD coding to resolve any challenges </p>
                                </div>
                            </div>
                            <div className="card__img "></div>
                        </article>
                    </div>
                    <div className="col-lg-4 col-12  mt-4 mt-lg-0">
                        <article className="card1 card--2">
                            <div className="card__img"></div>
                            <div className="card__content">
                                <div className="card__info text-start">
                                    <p className="py-2 m-0 card__category ">Efficiency & Expertise</p>
                                    <p className='card-center-title '>AR Clean-Up Roadmap for Medical Practices Using ProBill RCM Solutions
                                    </p>
                                    <p className=" card__title ">Enhancing process efficiency, and providing expert guidance on billing documentation, CPT, and ICD coding to resolve any challenges </p>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="col-lg-4 col-12  mb-lg-0 mb-3 mt-lg-0">
                        <article className="card1 card--3">
                            <div className="card__img"></div>
                            <div className="card__content">
                                <div className="card__info text-start px-2">
                                    <p className="py-2 m-0 card__category ">Efficiency & Expertise</p>
                                    <p className='card-center-title '>We assist clients in optimizing their medical revenue cycle management,</p>
                                    <p className=" card__title ">Enhancing process efficiency, and providing expert guidance on billing documentation, CPT, and ICD coding to resolve any challenges </p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div> */}
            {/* Let's Get In Touch */}
            <div className="frame mt-lg-0  ">
                <div className="container mt-lg-1 ">
                    <div className="row mx-auto mt-5 mt-lg-0">
                        <div className="col-lg-8 m-0 col-12  mt-0 p-3 text-lg-start flex-column d-flex justify-content-center">
                            <h3 className='text-white contact-text  m-0 mt-lg-4 mt-4'>
                                Get An <span className='contact-text-orange'>Expert Assessment</span> today

                            </h3>
                            <p className=' any-para mt-3 mt-lg-1' style={{ color: 'rgba(255, 255, 255, 1)' }}>Join Forces with Us to Become a Leading Innovator in the Healthcare Industry!</p>
                        </div>
                        <div className="col-lg-4 col-12 mt-lg-4   p-3 d-flex justify-content-lg-end justify-content-center  align-items-lg-center">
                            <button className="btn bg-white contact-btn-2 py-1 d-flex align-items-center justify-content-center px-3">
                                <a href="tel:+1234234456" className='nav-link text-decoration-none px-0 p-0 d-flex align-items-center text-dark'>

                                    <i className="bi bi-telephone pe-2" style={{ color: '#FA9F3F', fontSize: "20px" }}></i>
                                    <pre className='nav-link border-0 d-none d-lg-block text-dark px-0'>+1234-234-456</pre>
                                    <p className='nav-link border-0 d-lg-none text-dark px-0'>+1234-234-456</p>

                                </a>

                            </button>
                            <button className="btn  bg-white contact-btn-2 py-1 d-flex align-items-center justify-content-center mx-3 p-2">
                                <a className="nav-link text-decoration-none px-0 p-0 d-flex align-items-center text-dark" href="https://mail.google.com/mail/u/0/#inbox" target="_blank" rel="noopener noreferrer">
                                    <i className="bi bi-envelope pe-2" style={{ color: '#FA9F3F', fontSize: "20px" }}></i>
                                    @ProBillRCM.com
                                </a>
                            </button>


                            {/* <button className="btn d-lg-none bg-white contact-btn-2 py-1 d-flex align-items-center justify-content-center mx-3" > <i className="bi bi-envelope pe-2" style={{ color: '#FA9F3F', fontSize: "20px" }}></i>  @ProBillRCM.com</button> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* step button */}
            <div className="step-bg-img">
                <div className="container mt-lg-5 mt-4 mb-lg-5">
                    <div className="row mx-auto">
                        <div className="col-12 text-center justify-content-center mb-lg-5 ">
                            <div className="div d-flex align-items-center justify-content-center">
                                <h3 className="text-probill-rcm d-flex align-items-center me-2 mb-0 pb-0">
                                    ProBill
                                    <span
                                        className="mx-2  d-none d-lg-block gradient-box d-flex align-items-center justify-content-center"
                                        style={{ borderRadius: "12px", width: "49px", height: "33px" }}
                                    >
                                        <img src={arrowimg} className='d-flex justify-content-center align-items-center mt-1 mx-auto' style={{ width: '24px', height: '24px' }} alt="Arrow" />
                                    </span>
                                    RCM
                                    <span className="text-probill-rcm-orange ms-2 ">Services</span>
                                </h3>
                            </div>

                            <p className='mt-lg-3 m-0 any-para'>Our Solution-Based Healthcare Revenue Cycle Management (RCM) Services
                            </p>
                        </div>
                    </div>
                    {/* <div className="row  mx-auto justify-content-center mb-4">
                    <div className="col-12  d-flex justify-content-center">
                        <button
                            type="button"
                            className={`btn step-btn m-2 shadow ${activeTab === 1 ? 'active' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Accounts Receivable
                        </button>
                        <button
                            type="button"
                            className={`btn step-btn m-2 shadow ${activeTab === 2 ? 'active' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Denial Management services
                        </button>
                        <button
                            type="button"
                            className={`btn step-btn m-2 shadow ${activeTab === 3 ? 'active' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Charge entry & Audit
                        </button>
                        <button
                            type="button"
                            className={`btn step-btn m-2 shadow ${activeTab === 4 ? 'active' : ''}`}
                            onClick={() => handleTabClick(4)}
                        >
                            Payment posting
                        </button>
                    </div>
                </div> */}
                    {/* Content Area */}
                    {/* <div className="row mx-auto ">
                    <div className="col-12 mt-lg-5  ">
                        <div className="row">
                            {activeTab === 1 && (
                                <>
                                    <div className="col-lg-6 col-12 mt-3 text-start">
                                        <p className='step-heading '>ProBill RCM growing Accounts Receivable Management Services
                                        </p>
                                        <p className='step-para'> ProBill RCM is a trusted Accounts Receivable Management company dedicated to improving cash flow for healthcare providers through advanced billing and collection services. </p>
                                        <img src={account_step} className='d-flex justify-content-center align-items-center img-fluid d-lg-none d-block account-img' alt="" />
                                        <div className="div keybanefits-step">
                                            <p className='py-lg-2 benefits m-0 mt-lg-0 mb-3'><b>Key Benefits</b></p>
                                            <div className="d-flex m-lg-0 align-items-center ">
                                                <div className="d-flex images  mt-lg-1 m-lg-0 ">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits d-flex  m-lg-0 px-lg-3'>
                                                    Faster payments for steady cash flow </p>
                                            </div>
                                            <div className="d-flex m-lg-0 mt-lg-0 mt-2 align-items-center ">
                                                <div className="d-flex images  mt-lg-3 m-lg-0">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits px-lg-3 m-lg-0 mt-lg-3 '> Expertise in resolving denials and managing claims </p>
                                            </div>
                                            <div className="d-flex m-lg-0 mt-lg-0 mt-2 align-items-center ">
                                                <div className="d-flex images  m-lg-0 mt-lg-3">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits px-lg-3 m-lg-0 mt-lg-3'> Reduce operating costs by up to 40%  </p>
                                            </div>
                                            <div className="d-flex mt-lg-0 mt-2 step-bg-img align-items-center ">
                                                <div className="d-flex images mt-2 mt-lg-1">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits px-lg-3'> Timely follow-ups to minimize delays and boost recoveries </p>
                                            </div>
                                            <div className="apply-btn mt-lg-5 mt-3  "><button className="btn " onClick={handleShow} >Apply Now</button><i className="bi bi-arrow-up-right px-2"></i></div>
                                        </div>
                                    </div>
                                    <div className="col-6 d-none d-lg-block ">
                                        <img src={account_step} className='img-fluid account-img' alt="" />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="row">
                            {activeTab === 2 && (
                                <>
                                    <div className="col-12 col-lg-6 mt-3 text-start">
                                        <h3 className='step-heading'>ProBill RCM Simplifying Denials and Appeals Management</h3>
                                        <p className='step-para'>ProBill RCM streamlines the denial and appeal process, helping healthcare providers focus on business growth. Our solutions reduce manual tasks, improve claim resolution, and ensure quicker payments through effective communication and trend analysis.</p>
                                        <img src={account_step2} className='d-lg-none d-block img-fluid account-img' alt="" />
                                        <div className="keybanefits-step">
                                            <p className='py-2'><b>Key Benefits</b></p>
                                            <div className="d-flex m-lg-0 align-items-center ">
                                                <div className="d-flex images  mt-lg-1 m-lg-0 ">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits  m-lg-0 px-lg-3'>
                                                    Automates Denials and Appeals, reducing manual effort </p>
                                            </div>
                                            <div className="d-flex m-lg-0 align-items-center ">
                                                <div className="d-flex images  mt-lg-3 m-lg-0">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits px-lg-3 m-lg-0 mt-lg-3 '>Investigates and resolves denied or underpaid claims </p>
                                            </div>
                                            <div className="d-flex m-lg-0 align-items-center ">
                                                <div className="d-flex images  m-lg-0 mt-lg-3">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits px-lg-3 m-lg-0 mt-lg-3'> Communicates with patients and insurers to speed up payments  </p>
                                            </div>
                                            <div className="d-flex step-bg-img align-items-center ">
                                                <div className="d-flex images  mt-lg-1">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits px-lg-3'> Analyzes denial trends to prevent future issues and improve reimbursement rates</p>
                                            </div>
                                            <div className="apply-btn mt-lg-5 mt-3 "><button className="btn" onClick={handleShow}>Apply Now</button><i className="bi bi-arrow-up-right px-2"></i></div>
                                        </div>
                                    </div>
                                    <div className="col-6 d-none d-lg-block">
                                        <img src={account_step2} className='img-fluid account-img' alt="" />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="row">
                            {activeTab === 3 && (
                                <>
                                    <div className="col-12 col-lg-6 mt-3 text-start">
                                        <h3 className='step-heading'>ProBill RCM Charge Entry Audit Service</h3>
                                        <p className='step-para'>ProBill RCM offers a comprehensive Charge Entry Audit service to ensure accurate billing and minimize errors. Our team reviews each charge entry for accuracy and compliance, helping healthcare providers maintain proper records and avoid revenue leakage. </p>
                                        <img src={account_step3} className='d-lg-none d-block img-fluid account-img' alt="" />
                                        <div className="keybanefits-step">
                                            <p className='py-2'><b>Key Benefits</b></p>
                                            <div className="d-flex m-lg-0 align-items-center ">
                                                <div className="d-flex images mt-1 mt-lg-1 m-lg-0 ">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits  m-lg-0 px-lg-3'>
                                                    Ensures accurate billing and compliance with industry standards </p>
                                            </div>
                                            <div className="d-flex m-lg-0 align-items-center ">
                                                <div className="d-flex images mt-1 mt-lg-3 m-lg-0">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits px-lg-3 m-lg-0 mt-lg-3 '> Identifies and corrects charge entry errors to prevent revenue loss </p>
                                            </div>
                                            <div className="d-flex m-lg-0 align-items-center ">
                                                <div className="d-flex images mt-1 m-lg-0 mt-lg-3">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits px-lg-3 m-lg-0 mt-lg-3'> Streamlines the charge entry process for faster reimbursements </p>
                                            </div>
                                            <div className="d-flex step-bg-img align-items-center ">
                                                <div className="d-flex images mt-1 mt-lg-1">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits px-lg-3'> Regular audits to maintain accuracy and improve financial health </p>
                                            </div>
                                            <div className="apply-btn mt-lg-5 mt-3 "><button className="btn" onClick={handleShow}>Apply Now</button><i className="bi bi-arrow-up-right px-2"></i></div>
                                        </div>
                                    </div>
                                    <div className="col-6 d-none d-lg-block">
                                        <img src={account_step3} className='img-fluid account-img' alt="" />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="row">
                            {activeTab === 4 && (
                                <>
                                    <div className="col-12 col-lg-6 mt-3 text-start">
                                        <h3 className='step-heading'>ProBill RCM Quick and Accurate Payment Posting
                                        </h3>
                                        <p className='step-para'>ProBill RCM offers a highly efficient Payment Posting service, ensuring swift and accurate processing of all payment types. From patient payments to insurance and contract payments, our team handles the full scope of posting needs, reducing payment delays and maintaining a smooth revenue cycle for healthcare providers. </p>
                                        <img src={account_step4} className='d-lg-none d-block img-fluid account-img-4' alt="" />
                                        <div className="keybanefits-step">
                                            <p className='py-2'><b>Key Benefits</b></p>
                                            <div className="d-flex m-lg-0 align-items-center ">
                                                <div className="d-flex images mt-1 mt-lg-1 m-lg-0 ">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits  m-lg-0 px-lg-3'>
                                                    Quick, accurate processing of checks, paper, and electronic payments </p>
                                            </div>
                                            <div className="d-flex m-lg-0 align-items-center ">
                                                <div className="d-flex images mt-1 mt-lg-3 m-lg-0">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits px-lg-3 m-lg-0 mt-lg-3 '> Handles contract payments, adjustments, and lockbox postings </p>
                                            </div>
                                            <div className="d-flex m-lg-0 align-items-center ">
                                                <div className="d-flex images mt-1 m-lg-0 mt-lg-3">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-4 key-banefits px-lg-3 m-lg-0 mt-lg-3'>Ensures accurate recording of patient and insurance payments to avoid denials and errors </p>
                                            </div>
                                            <div className="d-flex step-bg-img align-items-center ">
                                                <div className="d-flex images mt-1 mt-lg-1">
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                    <img src={arrow} alt="" style={{ width: '15px', height: '20px' }} />
                                                </div>
                                                <p className='px-3 key-banefits px-lg-3'> Streamlined processes to improve cash flow and minimize payment delays </p>
                                            </div>
                                            <div className="apply-btn mt-lg-5 mt-3 "><button className="btn" onClick={handleShow}>Apply Now</button><i className="bi bi-arrow-up-right px-2"></i></div>
                                        </div>
                                    </div>
                                    <div className="col-6 d-none d-lg-block">
                                        <img src={account_step4} className='img-fluid account-img' alt="" />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div> */}
                    <div className="row">
                        <div className="col-lg-6 col-12 mt-lg-0 mt-2">
                            <div className="div text-lg-start text-center">
                                <div className="div service-header">
                                    <img src={rcm_logo} className="img-fluid about-logo mb-2 mb-lg-0" alt="" />
                                    <span className='px-3 m-0'>
                                        growing Accounts Receivable Management Services
                                    </span>
                                </div>
                                <p className="card-text text-muted mt-md-0 mt-1">
                                    ProBill RCM is a trusted Accounts Receivable Management company dedicated to improving cash flow for healthcare providers through advanced billing and collection services.
                                </p>
                                {/* <Accordion activeKey={activeAccordion} onSelect={handleAccordionToggle}>
                                    <Accordion.Item className="border-0 border-top" eventKey="0">
                                        <Accordion.Header className='d-flex'> <div className='accordion-icon rounded-circle p-2' style={{ background: 'linear-gradient(180deg, #CA3C0D 0%, #9C1E21 100%)' }}><img src={accordion_icon1} alt="icon" className='img-fluid' /></div> <h2 className="accordion-header px-3 ">Accounts Receivable</h2></Accordion.Header>
                                        <Accordion.Body className="accordion-body text-muted">
                                            <p className="m-1">. Faster payments for steady cash flow</p>
                                            <p className="m-1">. Expertise in resolving denials and managing claims</p>
                                            <p className="m-1">. Reduce operating costs by up to 40%</p>
                                            <p className="m-1">. Timely follow-ups to minimize delays and boost recoveries</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item className="border-0 border-top " eventKey="4">
                                        <Accordion.Header className='d-flex'> <div className='accordion-icon  rounded-circle p-2' style={{ background: 'rgba(225, 73, 56, 1)' }}><img src={accordion_icon6} alt="icon" className='img-fluid' /></div><h2 className="accordion-header px-3">Account Reconciliation</h2></Accordion.Header>
                                        <Accordion.Body className="accordion-body text-muted">
                                            <p className="m-1">. Faster payments for steady cash flow</p>
                                            <p className="m-1">. Expertise in resolving denials and managing claims</p>
                                            <p className="m-1">. Reduce operating costs by up to 40%</p>
                                            <p className="m-1">. Timely follow-ups to minimize delays and boost recoveries</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item className="border-0 border-top" eventKey="1">
                                        <Accordion.Header className='d-flex'> <div className='accordion-icon rounded-circle p-2' style={{ background: ' rgba(242, 167, 28, 1)' }}><img src={accordion_icon2} alt="icon" className='img-fluid' /></div><h2 className="accordion-header px-3">Denial Management services</h2></Accordion.Header>
                                        <Accordion.Body className="accordion-body text-muted">
                                            <p className="m-1">. Faster payments for steady cash flow</p>
                                            <p className="m-1">. Expertise in resolving denials and managing claims</p>
                                            <p className="m-1">. Reduce operating costs by up to 40%</p>
                                            <p className="m-1">. Timely follow-ups to minimize delays and boost recoveries</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item className="border-0 border-top" eventKey="2">
                                        <Accordion.Header className='d-flex'> <div className='accordion-icon rounded-circle p-2' style={{ background: 'rgba(52, 175, 190, 1)'  }}><img src={accordion_icon3} alt="icon" className='img-fluid' /></div><h2 className="accordion-header px-3">Charge Entry & Audit</h2></Accordion.Header>
                                        <Accordion.Body className="accordion-body text-muted">
                                            <p className="m-1">. Faster payments for steady cash flow</p>
                                            <p className="m-1">. Expertise in resolving denials and managing claims</p>
                                            <p className="m-1">. Reduce operating costs by up to 40%</p>
                                            <p className="m-1">. Timely follow-ups to minimize delays and boost recoveries</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item className="border-0 border-top border-bottom" eventKey="3">
                                        <Accordion.Header className='d-flex'> <div className='accordion-icon rounded-circle p-2' style={{ background: 'linear-gradient(180deg, #C5BADA 0%, #6D4999 100%)'  }}><img src={accordion_icon4} alt="icon" className='img-fluid' /></div><h2 className="accordion-header px-3">Payment Posting</h2></Accordion.Header>
                                        <Accordion.Body className="accordion-body text-muted">
                                            <p className="m-1">. Faster payments for steady cash flow</p>
                                            <p className="m-1">. Expertise in resolving denials and managing claims</p>
                                            <p className="m-1">. Reduce operating costs by up to 40%</p>
                                            <p className="m-1">. Timely follow-ups to minimize delays and boost recoveries</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                 
                                  
                                </Accordion> */}
                                <div>
                                    <div className="border-0 border-top mb-2 accordion-width">
                                        <div className="d-flex mt-2 ">
                                            <div
                                                className="accordion-icon rounded-circle mt-lg-2 mb-lg-2 p-2"
                                                style={{ background: "rgba(29, 68, 96, 1)" }}
                                            >
                                                <img src={accordion_icon1} style={{ height: '30px', width: '27px' }} alt="icon" className="img-fluid" />
                                            </div>
                                            <h2 className="accordion-header d-flex justify-content-center align-items-center px-3 ">Accounts Receivable</h2>
                                        </div>

                                    </div>
                                    <div className="border-0 border-top mb-2 accordion-width">
                                        <div className="d-flex mt-2 ">
                                            <div
                                                className="accordion-icon rounded-circle p-2 mt-lg-2 mb-lg-2"
                                                style={{ background: "rgba(225, 73, 56, 1)" }}
                                            >
                                                <img src={accordion_icon6} style={{ height: '24px', width: '29px' }} alt="icon" className="img-fluid" />
                                            </div>
                                            <h2 className="accordion-header d-flex justify-content-center align-items-center px-3 ">Account Reconciliation</h2>
                                        </div>

                                    </div>
                                    <div className="border-0 border-top mb-2 accordion-width">
                                        <div className="d-flex mt-2">
                                            <div
                                                className="accordion-icon rounded-circle p-2 mt-lg-2 mb-lg-2"
                                                style={{ background: "rgba(242, 167, 28, 1)" }}
                                            >
                                                <img src={accordion_icon2} style={{ height: '30px', width: '27px' }} alt="icon" className="img-fluid" />
                                            </div>
                                            <h2 className="accordion-header d-flex justify-content-center align-items-center px-3 ">Denial Management Services</h2>
                                        </div>

                                    </div>
                                    <div className="border-0 border-top mb-2 accordion-width">
                                        <div className="d-flex mt-2">
                                            <div
                                                className="accordion-icon rounded-circle p-2 mt-lg-2 mb-lg-2"
                                                style={{ background: "rgba(52, 175, 190, 1)" }}
                                            >
                                                <img src={accordion_icon3} style={{ height: '30px', width: '27px' }} alt="icon" className="img-fluid" />
                                            </div>
                                            <h2 className="accordion-header d-flex justify-content-center align-items-center px-3 ">Charge Entry & Audit</h2>
                                        </div>

                                    </div>
                                    <div className="border-0 border-top   mb-2 mt-lg-1 accordion-width">
                                        <div className="d-flex mt-2 mt-lg-2 mb-3">
                                            <div
                                                className="accordion-icon rounded-circle   p-2 mt-lg-2 mb-lg-2"
                                                style={{ background: "linear-gradient(180deg, #C5BADA 0%, #6D4999 100%)" }}
                                            >
                                                <img src={accordion_icon4} style={{ height: '30px', width: '27px' }} alt="icon" className="img-fluid mx-auto" />
                                            </div>
                                            <h2 className="accordion-header d-flex justify-content-center align-items-center px-3 ">Payment Posting</h2>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-12 d-lg-flex justify-content-lg-end   ">
                            {/* Display the image based on the active accordion */}
                            <img src={getImageToShow()} className="accordion-img  mt-lg-5 mt-4 px-lg-1" alt="Service illustration" />
                        </div>
                    </div>
                </div>
            </div>
            {/*brands logo */}
            {/* <div className="bg-frame mt-lg-5 mt-3">
                <div className="container">
                    <div className="row mx-auto">
                        <div className="col-12 py-lg-5 py-3 text-center "><p className='fs-5 text-white'>Trusted By the 1000+ Modern And Companies</p></div>
                        <div className="col-2 mx-auto"><img src='https://img.freepik.com/free-vector/hospital-logo-design-vector-medical-cross_53876-136743.jpg' className='img-fluid brand-logo' alt="" /></div>
                        <div className="col-2 mx-auto"><img src='https://img.freepik.com/free-vector/hospital-logo-design-vector-medical-cross_53876-136743.jpg' className='img-fluid brand-logo' alt="" /></div>
                        <div className="col-2 mx-auto"><img src='https://img.freepik.com/free-vector/hospital-logo-design-vector-medical-cross_53876-136743.jpg' className='img-fluid brand-logo' alt="" /></div>
                        <div className="col-2 mx-auto"><img src='https://img.freepik.com/free-vector/hospital-logo-design-vector-medical-cross_53876-136743.jpg' className='img-fluid brand-logo' alt="" /></div>
                        <div className="col-2 mx-auto"><img src='https://img.freepik.com/free-vector/hospital-logo-design-vector-medical-cross_53876-136743.jpg' className='img-fluid brand-logo' alt="" /></div>
                    </div>
                </div>
            </div> */}
            {/* Our Positive Social Impact */}
            {/* <div className="container mt-lg-5 mt-2 card-bg-vector">
                <div className="row mx-auto">
                    <div className="col-lg-8 col-12 m-0 mt-lg-4 text-start">
                        <h2 className=" text-dark text-probill-rcm m-0 mt-lg-0 mt-2 ">ProBill RCM  <span className="text-probill-rcm-orange">Clients Testimonials</span></h2>
                        <p className='mt-lg-3 mt-1 text-probill-para m-0'>Trusted by Healthcare Professionals</p>
                    </div>
                    <div className="col-lg-4 mt-lg-4 col-12 text-lg-end text-center">
                        <h5 className='me-3'><img src={onestart} alt="" /> Trustpilot</h5>
                        <div className="d-flex justify-content-lg-end justify-content-center align-items-end">
                            <div className="d-flex justify-content-between ">

                                <div className="div star mx-1 d-flex align-item-center ">
                                    <img src={start} className='mx-auto img-fluid' alt="" />
                                </div>
                                <div className="div star mx-1 d-flex align-item-center">
                                    <img src={start} className='mx-auto img-fluid' alt="" />
                                </div>
                                <div className="div star mx-1 d-flex align-item-center">
                                    <img src={start} className='mx-auto img-fluid' alt="" />
                                </div>
                                <div className="div star mx-1 d-flex align-item-center">
                                    <img src={start} className='mx-auto img-fluid' alt="" />
                                </div>
                                <div className="div star mx-1 d-flex align-item-center">
                                    <img src={halfstar} className='mx-auto img-fluid' alt="" />
                                </div>

                            </div>
                        </div>
                        <p>Total Score 4.4</p>
                    </div>
                </div>

            </div> */}
            {/* <div className="container-fluid  mt-lg-5">
                <div className="row mx-auto card-bg-vect">
                    <div className="quote-symbol-container px-5">
                        <img src={card_vector} className="quote-symbol" alt="quote" />
                        <img src={card_vector} className="quote-symbol" alt="quote" />
                    </div>
                    <div className="col-12 ">
                        <div className="d-flex">
                            {testimonials.slice(currentIndex, currentIndex + Math.floor(visibleCards)).map((testimonial) => (
                                <Card key={testimonial.id} className="h-100 slide-card mx-lg-4 w-100" style={{ borderRadius: '20px' }}>
                                    <Card.Body className="d-flex flex-column mt-4 px-4">
                                        <h3 className="text-start m-0 card-heading text-dark">{testimonial?.title}</h3>
                                        <Card.Text className="text-muted text-start insight-desc flex-grow-1">
                                            {testimonial?.quote}
                                        </Card.Text>
                                        <div className="d-flex align-items-center mt-3 mb-3">
                                            <img
                                                src={testimonial.img}
                                                alt={testimonial.name}
                                                className="rounded-5 me-3 card-user-img"

                                            />
                                            <div className="text-start">
                                                <h6 className="mb-0 text-dark m-0 card-user-name">{testimonial.name}</h6>
                                                <span className="text-muted mb-0 card-rating">{testimonial.role}</span>
                                            </div>
                                            <div className="ms-auto mt-2 mx-0 align-items-center">
                                                <div className="d-flex">
                                                    <img src={onestart} alt="Star" className="m-0" style={{ width: '20px', height: '20px' }} />
                                                    <span className="ms-1 text-dark m-0" style={{ fontWeight: '600', fontFamily: 'poppins', fontSize: '16px' }}>{testimonial.rating}</span>
                                                </div>
                                                <span className="text-muted card-rating">Rating</span>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="container border-bottom mt-lg-5 mt-3">
                    <div className="row mx-auto mb-lg-5 mb-3">
                        <div className="d-flex col-lg-8 col-4 gap-2">
                            <Button onClick={handlePrev} disabled={currentIndex === 0} className="btn fw-bold bg-white rounded-5 text-dark border-white shadow ">
                                <i className="bi bi-arrow-left fw-bold"></i>
                            </Button>
                            <Button onClick={handleNext} disabled={currentIndex >= testimonials.length - visibleCards} className="btn bg-white text-dark border-white shadow fw-bold">
                                <i className="bi bi-arrow-right"></i>
                            </Button>
                        </div>

                     
                    </div>
                </div>
            </div> */}
            {/* <div className="container  mt-lg-3 mb-lg-5 mb-3">
                <div className="row mx-auto">
                    <div className="col-8 mt-lg-4 mt-lg-5 mt-3 text-lg-start d-flex text-center mb-lg-4 px-lg-0 ">
                        <h1 className="text-probill-rcm text-dark mt-lg-0 mt-0  "> About </h1>
                        <img src={logo} className="img-fluid mt-lg-3 mt-1 mx-lg-2 about-logo " alt="logo" />
                    </div>
                </div>
                <div className="row mx-auto">
                    <div className="col-12 col-lg-4 d-flex justify-content-center mt-lg-0 mt-1">
                        <div className='row mx-auto'>
                            <div class="card_data ">
                                <div class="card-image">
                                    <img src={insight1} alt="Card Image" className='rounded-4' />
                                </div>
                                <div class="card-content">
                                    <div className="div text-start">
                                        <div className="d-flex justify-content-between mb-2">
                                            <button className="btn" style={{ backgroundColor: '#FA9F3F14', borderRadius: '12px' }}>Probill RCM solutions</button>
                                        </div>
                                        <p className='text-muted insight-desc m-lg-0'>With extensive experience in Physician Medical Billing Services, Probill RCM is your trusted partner for comprehensive end-to-end medical billing solutions tailored to the healthcare industry. We serve a diverse range of organizations, including providers,
                                            payers, ambulatory surgical centers (ASCs), pharmaceutical companies, and medical device firms. </p>
                                        <p className='text-muted insight-desc m-0'>Our commitment to excellence in business process management (BPM) ensures that we meet the unique
                                            needs of each market we serve. Through continuous training and adherence to strict quality standards, we maintain a consistent level of service that prioritizes client satisfaction.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><div className="col-12 col-lg-4 d-flex justify-content-center mt-lg-0 mt-3">
                        <div className='row mx-auto'>
                            <div class="card_data">
                                <div class="card-image">
                                    <img src={insight2} alt="Card Image" />
                                </div>
                                <div class="card-content">
                                    <div className="div text-start">
                                        <div className="d-flex justify-content-between mb-2">
                                            <button className="btn" style={{ backgroundColor: '#FA9F3F14', borderRadius: '12px' }}>ProBill RCM Mission</button>
                                        </div>
                                        <p className='text-muted insight-desc m-lg-0'>Our mission is to deliver the most accurate and current medical billing services to our clients. We prioritize personalized service and clear communication, ensuring thorough insurance follow-ups and prompt billing processes </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><div className="col-12 d-flex justify-content-center col-lg-4 mt-lg-0 mt-3">
                        <div className='row mx-auto'>
                            <div class="card_data">
                                <div class="card-image">
                                    <img src={insight3} alt="Card Image" />
                                </div>
                                <div class="card-content">
                                    <div className="div text-start">
                                        <div className="d-flex justify-content-between mb-2">
                                            <button className="btn" style={{ backgroundColor: '#FA9F3F14', borderRadius: '12px' }}>Why Choose Us</button>
                                        </div>
                                        <p className='text-muted insight-desc m-lg-0'>ProBill RCM Solutions is a rising star in the healthcare industry, committed to being your trusted medical billing partner. Our expert billing team stays ahead of industry updates and employs proven strategies to maximize reimbursements and enhance revenue.<br />
                                            Here are four compelling reasons to choose ProBill RCM Solutions for your practice:<br />
                                            1. Expert Billing Specialists
                                            Our skilled billing team tackles challenges head-on, streamlining your workflow to help maximize revenue and minimize billing errors.<br />
                                            2. Transparency
                                            We believe in transparent processes, clear communication, and honest fees, ensuring our practices are aligned with your needs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="about-bg-img p-lg-1 mt-lg-3 " style={{ backgroundColor: 'rgba(246, 246, 246, 0.75)' }}>
                <div className="about-bg-img2">
                    <div className="container p-lg-2 mb-lg-5  mt-4 mt-lg-5">
                        <div className="row">
                            <div className="col-12 col-lg-6 ">
                                <img src={about_left_img} className='img-fluid mt-1 w-100 about-left-img' alt="" />
                            </div>
                            <div className="col-12 col-lg-6 ">
                                <div className="div text-start">
                                    <div className="about">
                                        <div className="div about-head mt-2 mb-0">
                                            About <img src={logo} alt="" className="about-logo-bottom" />
                                        </div>
                                        <h4 className='m-0 about-head2'>The best team and best processes.</h4>
                                    </div>
                                    <div className="d-flex mt-lg-5 mt-3">
                                        <div className='number-bg-img p-lg-3 mt-lg-0 mt-3 text-lg-center'><p className='one'>1</p></div>
                                        <div className="div ms-lg-3">
                                            <h3 className='m-0 about-first-head'>ProBill RCM Solution</h3>
                                            <p className='m-0 mt-lg-2 about-first-desc' style={{ color: 'rgba(42, 48, 64, 0.5)' }}>We are your trusted partner for comprehensive, end-to-end revenue cycle management solutions tailored to the needs of healthcare providers. We proudly serve solo providers, medical practices, healthcare organizations, and ambulatory surgical centers (ASCs).
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex mt-2 mt-lg-5">
                                        <div className='number-bg-img1 p-lg-3 text-lg-center mt-lg-1 mt-3'><p className='one'>2</p></div>
                                        <div className="div ms-lg-3">
                                            <h3 className='m-0 about-first-head'>Probill RCM Vision</h3>
                                            <p className='m-0 mt-lg-2 about-first-desc' style={{ color: 'rgba(42, 48, 64, 0.5)' }}>Our vision is to be the leading partner in healthcare revenue cycle management, empowering providers and organizations to focus on delivering exceptional care by streamlining financial processes, maximizing efficiency, and ensuring sustainable growth.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex mt-2 mt-lg-5 mb-lg-0 mb-3">
                                        <div className='number-bg-img2 p-lg-3 text-lg-center mt-lg-0 mt-3'><p className='one'>3</p></div>
                                        <div className="div ms-lg-3">
                                            <h3 className='m-0 about-first-head'>Why Chose Probill</h3>
                                            <p className='m-0 mt-lg-2 about-first-desc' style={{ color: 'rgba(42, 48, 64, 0.5)' }}>ProBill RCM specializes in optimizing revenue cycle management, helping healthcare providers enhance cash flow with precision and efficiency. Our dedicated team leverages proven strategies and streamlined processes to deliver customized solutions that meet the unique needs of each practice or organization.
                                                By trusting ProBill RCM, healthcare providers can concentrate on delivering outstanding patient care while we handle the intricate details of billing, collections, and financial operations.

                                            </p>
                                            {/* <p className='m-0 about-first-desc'>By trusting ProBill RCM, healthcare providers can concentrate on delivering outstanding patient care while we handle the intricate details of billing, collections, and financial operations.</p> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* modal section */}
            <Modal show={showModal} onHide={handleClose} centered size='xl'>


                <Modal.Body>
                    <div className="container-fluid ">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="d-flex m-0 modal-heading justify-content-between">
                                    <h1>
                                        How can we{" "}
                                        <span style={{ color: "#E27239" }}>help you?</span>
                                    </h1>
                                    <button type="button" className="btn-close d-lg-none d-bloc" onClick={handleClose} data-bs-dismiss="modal" aria-label="Close"></button>

                                </div>
                                <p className="fs-6 m-0 d-lg-none" style={{ color: "#2A3040" }}>
                                    Within 24 hours, a representative from our company will
                                    contact you.
                                </p>
                                <form onSubmit={handleSubmit(onSubmit)} className="mt-lg-5 mt-3">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <input
                                                type="text"
                                                placeholder=" Full Name"
                                                className={`w-100 p-lg-2 p-1 contact-input  rounded ${errors.name ? "is-invalid" : ""
                                                    }`}
                                                style={{ border: "1px solid #A4A4A480" }}
                                                {...register("fullName", { required: "FullName is required" })}
                                            />
                                            {errors.fullName && (
                                                <p className="text-danger">{errors.fullName.message}</p>
                                            )}
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <input
                                                type="text"
                                                placeholder=" Cell Phone"
                                                className={`w-100 contact-input p-lg-2 p-1  rounded ${errors.cellPhone ? "is-invalid" : ""
                                                    }`}
                                                style={{ border: "1px solid #A4A4A480" }}
                                                {...register("cellPhone", {
                                                    required: "Cell phone is required",
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message: "Please enter a valid cell phone"
                                                    },
                                                    minLength: {
                                                        value: 10,
                                                        message: "Cell phone should be at least 10 digits"
                                                    }
                                                })}
                                            />
                                            {errors.cellPhone && (
                                                <p className="text-danger">{errors.cellPhone.message}</p>
                                            )}
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <input
                                                type="email"
                                                placeholder=" Email Address"
                                                className={`w-100 contact-input p-lg-2 p-1 rounded ${errors.email ? "is-invalid" : ""
                                                    }`}
                                                style={{ border: "1px solid #A4A4A480" }}
                                                {...register("email", {
                                                    required: "Email address is required",
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                                        message: "Please enter a valid email address"
                                                    }
                                                })}
                                            />
                                            {errors.email && (
                                                <p className="text-danger">{errors.email.message}</p>
                                            )}
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <textarea
                                                className={`w-100 contact-input p-2   rounded ${errors.message ? "is-invalid" : ""
                                                    }`}
                                                style={{ border: "1px solid #A4A4A480", height: "80px" }}
                                                rows="5"
                                                placeholder="Notes/Comments:"
                                                {...register("message", {
                                                    required: "Notes/Comments: is required",
                                                    minLength: {
                                                        value: 10,
                                                        message: "Message should be at least 10 characters long"
                                                    }
                                                })}
                                            ></textarea>
                                            {errors.message && (
                                                <p className="text-danger">{errors.message.message}</p>
                                            )}
                                        </div>

                                        <div className="col-md-12">
                                            <button
                                                type="submit"
                                                className=" mb-3  d-lg-none px-lg-5 px-2 btn-warnin rounded-4 py-lg-2 btn-warning border-0 "
                                                style={{ backgroundColor: "#E27239", borderRadius: '10px' }}
                                            >
                                                SUBMIT
                                            </button>
                                            <button
                                                type="submit"
                                                className=" mb-3 d-none d-lg-block px-lg-3 btn-warnin rounded-4  btn-warning border-0 "
                                                style={{ backgroundColor: "#E27239", fontSize: '16px', borderRadius: '10px', width: '110px', height: '35px' }}
                                            >
                                                SUBMIT
                                            </button>
                                            <p className="fs-6 d-none d-lg-block" style={{ color: "#2A3040" }}>
                                                Within 24 hours, a representative from our company will
                                                contact you.
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-lg-6 d-none d-lg-block ">
                                <div className="image-container">
                                    <img
                                        src={external1}
                                        alt="Team Working"
                                        className="w-100 h-100 diagonal-image"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </section>
    );
}
export default Section;
